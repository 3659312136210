
.LegendBox {

    background-color: rgb(255, 255, 255);
    width: auto;
    height: auto;
    border: 1px solid rgb(0, 0, 0);
    padding: 27px;
    right: 4%;
    top: 21%;
    position: absolute;
    z-index: 3;
    display: none;
    font-size: 10pt;
    overflow: auto;

}

.LayerForm {

text-align: justify;

}


.LayerButton {

    background-color: rgb(165, 200, 252);
    width: 5vh;
    height:4.5vh;
    border: 1px solid rgb(31, 31, 31);
    /* padding: 50px; */
    right: 4%;
    top: 10%;
    position: absolute;
    z-index: 3;
    border-radius: 5px;

}