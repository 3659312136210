html,
body {
  height: 100%;
}

#root { /* the root element of ReactJS injection */
  display: flex;
  height: 100%;
  width: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map {
  border: 1px solid rgb(0, 0, 0); /* just to show your wrapper */
  flex: 1;
  /* overflow:auto; */
  height:100%;
  width: 100%;
  background-color:#cccccc;
  Position:absolute;
  z-index:1;
}


.openlayers-map {
  height: 100% !important;
}
a.skiplink {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0;
  border: 0;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
a.skiplink:focus {
  clip: auto;
  height: auto;
  width: auto;
  background-color: #fff;
  padding: 0.3em;
}
#map:focus {
  outline: #4A74A8 solid 0.15em;
}

/* Pop Style */
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 12px;
  left: -50px;
  min-width: 280px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
.ol-popup-closer {
  text-decoration: none;
  position: absolute;
  top: 2px;
  right: 8px;
}
.ol-popup-closer:after {
  content: "✖";
}

.titleStyle {

/* position: absolute; */
/* z-index: 3; */
/* top: 4%; */
/* left: 5%; */
/* width: 4vh; */
/* line-height: 2.5rem; */

font-family: Arial, Helvetica, sans-serif;
color: white;
font-size: 14pt;

}

.roadMapStyle {

  width: 3vh;
  color:white;
}

.containerStyle {
  background-color: rgb(0, 0, 0) !important;
  position: absolute;
  z-index: 4;
  left: 4%;
  top: 3%;
  width: auto;
  text-align: center;
  padding: 0.5vh;
  border-radius: 1vh;
  opacity: 0.8;
}

@media only screen and (max-width: 935px) {
.containerStyle {
  background-color: rgb(0, 0, 0) !important;
  position: absolute;
  z-index: 4;
  left: 9%;
  top: 3%;
  }
}


.logoStyle {
  filter: drop-shadow(2px 2px 0 white) drop-shadow(-1px -2px 0 white);
  width: 9vh;
  height: 9vh;
}

.info {
    z-index: 1;
    opacity: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0;
    background: rgba(0,60,136,0.7);
    color: white;
    border: 0;
    transition: opacity 100ms ease-in;
  }

.layerIcon {
  position: absolute;
    top: 4%;
    left: 11%;
    width: 4vh;
}

.blankContainer {

/* position: absolute;
width: 100%;
height: 100%;
border: solid 1px black;
z-index: 0; */
/* opacity: 0; */

}


  .generalSearch {
    background-color: rgb(165, 200, 252);
    /* width: 3.8vh; */
    /* height:3.5vh; */
    border: 2px solid rgb(31, 31, 31);
    /* padding: 50px; */
    left: 0.5%;
    top: 17%;
    position: absolute;
    z-index: 3;
    border-radius: 5px;
  
  }

  .generalSearchBar {

    /* background-color: rgb(255, 255, 255);
    width: 25vh;
    height:3vh;
    border: 1px solid rgb(0, 0, 0);
    left: 3%;
    top: 17%;
    position: absolute;
    z-index: 3;
    text-align: left; */
    display: none;
    


  }

  .searchOutlineIcon {
    /* position: absolute; */
    /* top: 4%;
    left: 14%; */
    width: 3.5vh;
    color: white;
  }

  .searchButtonGeneralSearch {

    background-color: rgb(26, 26, 26);
    border-radius: 5px;
    border: 2px solid rgb(31, 31, 31);
    /* width: 4vh;
    height:3vh;
    border: 1px solid rgb(0, 0, 0);
    left: 14.5%;
    top: 17%;
    position: absolute;
    z-index: 3; */
    display: none;
  
  }

  .resultGeneralSearchBar {


    background-color: rgb(255, 255, 255);
    width: auto;
    height:auto;
    max-height: 60vh;
    border: 1px solid rgb(0, 0, 0);
    /* padding: 50px; */
    left: 5.8%;
    /* top: 26.5%; */
    top: 23.5%;
    position: absolute;
    z-index: 5;
    display: none;
    overflow:auto;
    font-size: small;
  }

  .generalSearchContainer {
    left: 3.8%;
    position: absolute;
    top: 17%;
    z-index: 2;
  }

  #closeButtonResultGS {
    border: none;
    background-color: transparent;
  }

  #closeButtonResultGS:hover {
   
    background-color: #777777;
    font-weight: bold;

  }

  /* @media only screen and (max-width: 935px) and (max-height: 738px) {
    .generalSearchBar {
      top: 9%;
      }
      .searchOutlineIcon{
        top: 21%;
        }

        .searchButtonGeneralSearch {
          top: 39%;
          }
    }
       */
    
  

  .binocularsIcon {
    /* position: absolute; */
    /* top: 4%; */
    /* left: 12%; */
    width: 3.5vh;
  }

  .bookIcon {
    /* position: absolute;
    top: 4%;
    left: 12%; */
    width: 3.5vh;
  }

  .searchIcon {
    /* position: absolute;
    top: 4%;
    left: 12%; */
    width: 3.5vh;
  }



  .advanceSearch {
    background-color: rgb(165, 200, 252);
    /* width: 3.8vh;
    height:3.5vh; */
    border: 2px solid rgb(31, 31, 31);
    /* padding: 50px; */
    left: 0.5%;
    top: 23.8%;
    position: absolute;
    z-index: 3;
    border-radius: 5px;
  }

  .advanceSearchBox {
    background-color: rgb(255, 255, 255);
    width: 25vh;
    height:33vh;
    border: 1px solid rgb(0, 0, 0);
    /* padding: 50px; */
    left: 3%;
    top: 15%;
    width: 25.3vh;
    display: none;
    position: absolute;
    z-index: 3;
  }

  .manual {
    background-color: rgb(165, 200, 252);
    /* width: 3.8vh;
    height:3.5vh; */
    border: 2px solid rgb(31, 31, 31);
    /* padding: 50px; */
    left: 0.5%;
    top: 30.8%;
    position: absolute;
    z-index: 3;
    border-radius: 5px;
  }

  /* @media only screen and (max-height: 738px) {
    .generalSearch {
      top: 20%;
      }
    .generalSearchBar {
      top: 20%;
    }
    .searchOutlineIcon{
      top: 19%;
    }
    .searchButtonGeneralSearch {
      top: 20%;
    }

    }

    @media only screen and (max-width: 1227px) {
      .generalSearchBar {
        top: 16%;
      }
      .searchOutlineIcon{
        top: 15%;
      }
      .searchButtonGeneralSearch {
        top: 16%;
      }

    }

    @media only screen and (max-width: 1227px) { 
      .searchOutlineIcon{
        left: 16%;
      }
      .searchButtonGeneralSearch {
        left: 16.5%;
      }

    }
    
    @media only screen and (max-width: 1143px) { 
      .generalSearchBar {
        left: 1.5%;
      }

      .searchOutlineIcon{
        left: 17%;
      }
      .searchButtonGeneralSearch {
        left: 17.5%;
      }

    } 
    
    @media only screen and (max-width: 930px) { 
      .searchOutlineIcon{
        left: 18%;
      }
      .searchButtonGeneralSearch {
        left: 18.5%;
      }

    } 
    
    @media only screen and (max-width: 800px) { 
      .searchOutlineIcon{
        left: 19%;
      }
      .searchButtonGeneralSearch {
        left: 19.5%;
      }

    }  

    @media only screen and (max-height: 738px) {
      .advanceSearch {
        top: 25%;
        }
      } */
      
      /* @media only screen and (max-height: 738px) {
        .manual {
          top: 30%;
          }
        } */
  

  .attributesInfoBox {

    /* background-color: transparent; */
    width: 35vh;
    height:40vh;
    /* border: 1px solid rgb(0, 0, 0); */
    /* padding: 50px; */
    left: 5.5%;
    top:41%;
    position: absolute;
    z-index: 3;

  }

  .textAreaAttInfo {
   
    height:51vh;

  }


  #attributeInfo {
    background-color: #f5f5f5;
    /* padding: 21px; */
    /* border-radius: 5px; */
    /* font-family: 'Courier New', monospace; */
    border: 1px solid rgb(0, 0, 0);
    position: absolute;
    /* height: 32vh; */
    /* width: 43.5vh; */
    max-height: 50vh;
    /* max-width: 44vh; */
    overflow: auto;
    border-collapse: collapse;
    font-size: 10pt;
    
  }

  #attributeInfo tr:nth-child(even) {
    background-color: rgb(175, 198, 220);
  }

  #attributeInfo th {
    background-color: rgb(121, 171, 221);
    text-align: center;
  }

  #attributeInfo td, #attributeInfo th {
    border: 1px solid #f5f5f5;
    /* width: 100%; */
  }
  
  #headerAttInfo {
    background-color: #ffffff;
    /* padding: 21px; */
    /* border-radius: 5px; */
    /* font-family: 'Courier New', monospace; */
    border: 1px solid rgb(255, 255, 255);
    /* position: absolute; */
    /* height: 32vh; */
    /* width: 43.5vh; */
    max-height: 50vh;
    /* max-width: 44vh; */
    overflow: auto;
    border-collapse: collapse;
    color: #777777;
    font-weight: bold;
    
  }


  #tdHeader {
    text-align: right;
    background-color: white;
  }

  #trData {
    line-height: 1;
  }


  .attributeInfoCloseButton {
    float: right;
    /* color:rgb(255, 255, 255); */
    /* position:fixed; */
    /* right: auto; */
    z-index: 4;
    display: none;
    /* background: #ff0000; */
    border-radius: 5px;
    /* font-size: 9pt; */
    /* width: 2.1vh;
    height: 2.1vh; */
    /* max-width: 2.1vh;
    max-height: 2.1vh; */
    /* line-height: 1.7vh; */
    /* text-align: right; */
    /* margin-top: 0.1vh; */
  }

  .closeIcon {

  z-index: 5;
  color: #cf0000;
  width: 3.5vh;

  }

.JlnPersekutuanIcon {

  height: 1vh;
  width: 2.1vh;
  border-bottom: solid black 1px;
  border-top: solid black 1px;
  background-color: rgb(255, 128, 128);

}

.JlnPahangIcon {

  height: 1vh;
  width: 2.1vh;
  border-bottom: solid black 1px;
  border-top: solid black 1px;
  background-color: rgb(62, 203, 255);
}

.SempMukimIcon {

  height: 2vh;
  width: 2vh;
  border: solid black 2px;
  background-color: rgb(255, 255, 255);
}

.KampungIcon {

  /* height: 2vh;
  /* width: 1.5vh; */
  border: solid rgb(191, 191, 191) 1px;
  color: #000000;
  font-weight:500;
  /* stroke: #fff; */
  /* text-shadow: #f5f5f5; */
  
  /* border-radius: 1vh; */
  /* background-color: rgb(0, 0, 0); */
}

.KoordinatJalanJKRIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid black 2px;
  border-radius: 1vh;
  background-color: rgb(186, 197, 68);

}

.KoordinatMulaJalanJKRandJPSIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 1vh;
  background-color: rgb(89, 255, 0);

}

.KoordinatAkhirJalanJKRandJPSIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid rgb(0, 0, 0) 1px;
  border-radius: 1vh;
  background-color: rgb(229, 3, 3);

}

.KoordinatMulaJalanJKRIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid rgb(186, 197, 68) 2px;
  border-radius: 1vh;
  background-color: rgb(89, 255, 0);

}

.KoordinatAkhirJalanJKRIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid rgb(186, 197, 68) 2px;
  border-radius: 1vh;
  background-color: rgb(200, 0, 0);

}


.KoordinatJalanJPSIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid black 2px;
  border-radius: 1vh;
  background-color: rgb(38, 138, 215);

}

.KoordinatMulaJalanJPSIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid rgb(38, 138, 215) 2px;
  border-radius: 1vh;
  background-color: rgb(89, 255, 0);

}

.KoordinatAkhirJalanJPSIcon {

  height: 1.5vh;
  width: 1.5vh;
  border: solid rgb(38, 138, 215) 2px;
  border-radius: 1vh;
  background-color: rgb(200, 0, 0);

}

.JlnKampungJKRIcon {

  height: 1vh;
  width: 2.1vh;
  border-bottom: solid black 1px;
  border-top: solid black 1px;
  background-color: rgb(237, 251, 86);

}

.JlnPertanianJPS {

  height: 1vh;
  width: 2.1vh;
  border-bottom: solid black 1px;
  border-top: solid black 1px;
  background-color: rgb(2, 181, 8);

}


