.AdvanceSearchDiv {

position: absolute;
width:100%;
height:100%;
z-index: 3;
background-color: white;


}

.navbarStyle {

    background-color: rgb(0, 0, 0);
    color: white;


}

.searchBoxCol {

border-color: black;
/* border-style: solid; */
background-color: rgb(227, 227, 227);
line-height: 1.5;
    
}

.dataTableCol {

border-color: black;
/* border-style: solid; */
background-color: rgb(227, 227, 227);
/* position:static; */
overflow: auto;
height: 80vh;
width: 100%;
max-height: 85vh;
/* max-width: 140vh; */
/* text-align: left; */

}

.buttonCari {

text-align: left;

}

.buttonCetak {
text-align: right    
}

.dropDownSearch {

width: 100%;
    
}

.rowHeader{
    line-height: 3.5;
    height: 12vh;
}

.alertMessage {
    display:inline-block;   
}


.spinner1 {

position: absolute;

}

.messageDisplayStyle {

    font-size: 14pt;
    
}

.operatorStyle {

width: 6vh;

}